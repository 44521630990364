<template>
  <div class="header">
    <!-- 电脑端导航 -->
    <div class="header-memu">
      <img class="logo" src="@/assets/images/logo.png" alt="" />
      <ul class="header-nav">
        <li
          v-for="(item, index) in navList"
          :key="index"
          :class="{ active: isActive(item.path) }"
        >
          <router-link :to="item.path">
            {{ item.name }}
            <i class="underline"></i>
          </router-link>
          <dl v-if="item.children.length > 0">
            <dt
              v-for="(i, n) in item.children"
              :key="n"
              :class="{ dlActive: isActive(i.path) }"
            >
              <router-link :to="i.path">{{ i.name }}</router-link>
            </dt>
          </dl>
        </li>
      </ul>
    </div>

    <div class="header-memu-m">
      <img src="@/assets/images/logo.png" alt="" />
      <img
        src="@/assets/images/menu.svg"
        style="cursor: pointer"
        @click="menuClick"
        alt=""
      />

      <div class="header-nav-m" v-if="navStatus">
        <div class="header-nav-m-content" style="width: 260px">
          <div class="m-menu-close">
            <img
              src="@/assets/images/close.png"
              style="width: 28px; cursor: pointer"
              @click="menuClick"
            />
          </div>
          <div>
            <ul class="header-nav-m-list">
              <li
                class="header-nav-m-item"
                v-for="(item, index) in navList"
                :class="{ active: isActiveM(item.path) }"
                :key="index"
                @click="goTo(item)"
              >
                <div
                  class="header-nav-m-item-title"
                  @click="item.show = !item.show"
                >
                  <span style="font-size: 18px">{{ item.name }}</span>
                  <img
                    v-if="item.children.length > 0 && item.show"
                    src="@/assets/images/up-arrow.png"
                    style="width: 20px"
                  />
                  <img
                    v-if="item.children.length > 0 && !item.show"
                    src="@/assets/images/down-arrow.png"
                    style="width: 20px"
                  />
                </div>

                <dl
                  v-if="item.children.length > 0 && item.show"
                  style="font-size: 16px; padding-left: 10px"
                >
                  <dt
                    v-for="(i, n) in item.children"
                    class="header-nav-m-item-subtitle"
                    :class="{ dtActive: isActiveM(i.path) }"
                    :key="n"
                    @click="goTo(i)"
                  >
                    {{ i.name }}
                  </dt>
                </dl>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      navStatus: false,
      navList: [
        {
          name: '首页',
          path: '/index',
          children: []
        },
        {
          name: '相关服务',
          path: '/service',
          children: []
        },
        {
          name: '合作案例',
          path: '/product',
          show: false,
          children: [
            {
              name: '中国农产品集采平台',
              path: '/product/digitalize'
            },
            {
              name: '欧常(上海)国际贸易',
              path: '/product/ai'
            },
            {
              name: '洪泰财富',
              path: '/product/rice'
            },
            {
              name: '数智执行系统',
              path: '/product/smartExecution'
            }
          ]
        },
        {
          name: '公司介绍',
          path: '/about',
          show: false,
          children: [
            {
              name: '公司详情',
              path: '/about/detail'
            },
            {
              name: '团队介绍',
              path: '/about/team'
            }
          ]
        },
        {
          name: '联系我们',
          path: '/contact',
          children: []
        }
      ]
    }
  },
  methods: {
    menuClick() {
      this.navStatus = !this.navStatus
      if (this.navStatus) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },
    goTo(item) {
      if (item.children && item.children.length > 0) {
        return
      }
      if (this.$route.path === item.path) {
        return
      }
      this.$router.push(item.path)
      this.menuClick()
    },
    isActiveM(path) {
      return this.$route.path === path
    },
    isActive(path) {
      // 当前路由与指定路径匹配或者是指定路径的子路径时返回 true
      return (
        this.$route.path === path || this.$route.path.startsWith(path + '/')
      )
    }
  }
}
</script>

<style lang="scss">
.header {
  background-color: rgb(255, 255, 255, 0.5);
  padding: 0 72px;

  .header-memu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-memu-m {
    display: none;
  }

  .logo {
    width: 115px;
    height: 40px;
  }
}
/* 导航栏 导航容器 */
.header-nav {
  list-style: none;
}
.header-nav {
  line-height: 64px;
  float: right;
  margin: 0;
  max-width: 800px;
}
/* 导航栏 每个导航 */
.header-nav > li {
  float: left;
  margin: 0 15px;
  position: relative;
}
/* 导航栏 每个导航下面的 a 链接 */
.header-nav > li > a {
  color: #000;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0;
  position: relative;
  text-decoration: none;
}
/* 导航栏 每个导航下面的 a 链接的下划线 */
.header-nav > li > a > i {
  display: block;
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 3px;
  opacity: 0;
  transition: all 0.6s ease;
  background-color: #003dbd;
}
/* 导航栏 每个导航下面的 a 链接的右侧小三角 */
.header-nav > li > a > span {
  font-size: 12px;
  transition: transform ease 0.5s;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去的样式 */
.header-nav > li > a:hover {
  color: #003dbd;
  text-decoration: none;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去下划线的样式 */
.header-nav > li > a:hover .underline {
  opacity: 1;
  width: 100%;
  left: 0;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去三角标的样式 */
.header-nav > li > a:hover span {
  transform: rotate(180deg);
}
/* 导航栏 每个导航下面的 a 链接 鼠标点击后的样式 */
.header-nav > li.active > a {
  color: #003dbd;
  text-decoration: none;
  // border-bottom: 4px solid #003dbd;
}
.header-nav > li.active > a .underline {
  opacity: 1;
  width: 100%;
  left: 0;
}
/* 导航栏 每个导航下面的二级导航容器 */
.header-nav > li > dl {
  display: none;
  position: absolute;
  top: 49%;
  left: -114%;
  z-index: 999999;
  box-shadow: 0 0 3px 1px #ccc;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  padding: 8px 8px 0 8px;
}
/* 导航栏 每个导航下面的二级导航容器的每个导航 */
.header-nav > li > dl > dt {
  width: 189px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 500;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  letter-spacing: 3px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin-bottom: 6px;
  padding: 4px 0;
}
.header-nav > li > dl > dt > a {
  color: #666666;
  text-decoration: none;
}
/* 导航栏 每个导航下面的二级导航容器的每个导航 当鼠标滑上时的样式*/
.header-nav > li > dl > dt > a:hover {
  text-decoration: none;
  width: 189px;
  color: #003dbd;
  border-radius: 2px;
}
/* 导航栏 滑上一级导航显示二级导航 */
.header-nav > li:hover dl {
  display: block;
}
.header-nav > li > dl > dt:hover {
  cursor: pointer;
  color: #003dbd;
  background: #deebfc;
  border-radius: 2px;
}
.header-nav > li > dl > dt:hover > a {
  cursor: pointer;
  color: #003dbd;
  background: #deebfc;
  border-radius: 2px;
}
.header-nav > li > dl > dt.dlActive {
  cursor: pointer;
  color: #003dbd;
  background: #deebfc;
  border-radius: 2px;
}
.header-nav > li > dl > dt.dlActive > a {
  text-decoration: none;
  width: 189px;
  color: #003dbd;
  border-radius: 2px;
}

@media screen and (max-width: 768px) {
  .header-memu {
    display: none !important;
  }
  .header-memu-m {
    display: flex !important;
    justify-content: space-between;
  }
  .header-nav-m {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.3);
    .header-nav-m-content {
      float: right;
      background: #fff;
      height: 100%;
      .m-menu-close {
        padding: 60px;
        text-align: right;
      }
      .header-nav-m-list {
        list-style: none;
        margin: 0;
        padding: 0 3rem;
        .header-nav-m-item {
          margin: 40px 0;
          cursor: pointer;
          font-weight: 500;
          .header-nav-m-item-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .header-nav-m-item-subtitle {
            margin: 30px 0;
          }
        }
        .active {
          color: #003dbd;
        }
        .dtActive {
          color: #003dbd;
        }
      }
    }
  }
}
</style>
